<script>
  import { router } from 'tinro'
  import { createEventDispatcher, onMount } from 'svelte'
  import { BASE_URL, lessonList, lessonPos } from './DataStore'

  import ResultDisplay2 from './exercise/ResultDisplay2.svelte'
  import SpeechToTextOpus from './exercise/SpeechToTextOpus.svelte'

  import TextToSpeech2 from './exercise/TextToSpeech2.svelte'
  import UploadResult from './exercise/UploadResult.svelte'
  import * as animateScroll from 'svelte-scrollto'
  import Instuctions from './exercise/Instuctions.svelte'

  import ExerciseRead from './ExerciseRead.svelte'
  import ExerciseQuestion from './ExerciseQuestion.svelte'
  import BannerWithLogo from './exercise/BannerWithLogo.svelte'

  import {userDetail} from './DataStore'

  export let loginDetail
  export let lessonPin
  export let exercisePin
  export let iframe = false
  export let lessonStatus

  let exerciseDetail = undefined

  let recordings = []
  let textToDisplay = undefined
  let textToRead = undefined

  let submitSuccess = false
  let resultData = undefined

  let recordingButtonState
  let errorMsg

  let retryCounter = 0

  const dispatch = createEventDispatcher()

  onMount(async () => {
    console.log('loginDetail', loginDetail, exercisePin)
    loadData()
  })

  function tryAgain() {
    retryCounter++
    recordings = []

    submitSuccess = false
    resultData = undefined

    recordingButtonState = undefined
    errorMsg = undefined
  }

  export function loadData() {
    $lessonPos = $lessonList.findIndex((e) => e.exerciseKey === exercisePin)
    errorMsg = ''
    fetch(BASE_URL + `public/api/exercises?key=` + exercisePin)
      .then((response) => {
        if (!response.ok) {
          console.log('response error')
          throw new Error('Network response was not ok')
        }
        console.log('response ok', response)
        return response.json()
      })
      .then((data) => {
        exerciseDetail = data
        //TODO
        if (exerciseDetail.type === 'READ') {
          textToDisplay = exerciseDetail?.text
        } else {
          textToDisplay = exerciseDetail?.question
        }
        console.log('textToDisplay', textToDisplay)
        textToRead = textToDisplay
        console.log('exericse data', exerciseDetail)
      })
      .catch((error) => {
        console.error('catch error get exercise', error)
        errorMsg = 'Missing PIN'
        exerciseDetail = undefined
      })
  }

  function handleNewRecording(event) {
    console.log(event)
    recordings = [...recordings, event.detail]
  }

  function handleResultSubmit(event) {
    console.log('handleResultSubmit', event)
    submitSuccess = true
    resultData = event.detail?.processResultData

    animateScroll.scrollToBottom()
  }

  function handleOpenLesson(event) {
    console.log('handleOpenLesson', event)
    // dispatch('openLesson', event?.detail)
    dispatch('openLesson', { lessonKey: event?.detail?.lessonKey })
  }

  function getSelectionText() {
    let textSelection = ''
    if (window.getSelection) {
      textSelection = window.getSelection().toString()
    } else if (document.selection && document.selection.type != 'Control') {
      textSelection = document.selection.createRange().text
    }

    if (textSelection && textSelection.length > 0) {
      textToRead = textSelection
    } else {
      textToRead = textToDisplay
    }

    console.log('selected text:', textSelection)

    return textSelection
  }

  function onChangeCleanUnicodeText(event) {
    //Here add more required trasnformations
    event.target.value = event?.target?.value.replace(/\u2019/g, "'")
    event.target.value = event?.target?.value.replace(/\2026/g, '.')
    exerciseDetail.selfPracticeText = event.target.value
  }

  function prevExercise() {
    console.log('prevExercise', {
      exerciseKey: $lessonList[$lessonPos - 1].exerciseKey,
    })
    dispatch('prevExercise', {
      exerciseKey: $lessonList[$lessonPos - 1].exerciseKey,
    })
  }

  function nextExercise() {
    console.log('nextExercise', {
      lessonPin: lessonPin,
      exerciseKey: $lessonList[$lessonPos + 1].exerciseKey,
    })
    dispatch('nextExercise', {
      lessonPin: lessonPin,
      exerciseKey: $lessonList[$lessonPos + 1].exerciseKey,
    })
  }
</script>

<div class="container mb-4">
  <BannerWithLogo />
</div>
{#if lessonStatus}
  <div style="display: flex; justify-content: center; align-items: center;">
    <!-- <button
      disabled={$lessonPos === 0}
      class="btn btn-navigation m-1"
      on:click={prevExercise}>&ShortLeftArrow;</button
    > -->
    <div>{$lessonPos + 1} of {$lessonList?.length}</div>
    <!-- <button
      disabled={$lessonPos === $lessonList?.length - 1}
      class="btn btn-navigation m-1"
      on:click={nextExercise}>&ShortRightArrow;</button
    > -->
  </div>
{/if}

{#if !exerciseDetail}
  <div style="display:flex; justify-content:center; margin-top: 4em;">
    Loading ...
  </div>
{:else if exerciseDetail.type === 'READ' && exerciseDetail.courseReportType === 'REPORT_V2'}
  <ExerciseRead
    on:openLesson={handleOpenLesson}
    on:openExercise={nextExercise}
    {textToDisplay}
    username={$userDetail?.userName}
    {loginDetail}
    {exerciseDetail}
    {iframe}
  />
{:else if exerciseDetail.type === 'QUESTION' || exerciseDetail.type === 'QUESTION_FIX_ANSWER'}
  <ExerciseQuestion
    on:openLesson={handleOpenLesson}
    on:openExercise={nextExercise}
    on:nextExercise={nextExercise}
    {textToDisplay}
    {loginDetail}
    {exerciseDetail}
  />
{:else}
  <div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <div class="exercise-title">
            {#if exerciseDetail}
              <span class="exercise-name">{exerciseDetail?.courseName}</span
              ><span class="exercise-name"> > </span><span class="exercise-name"
                >{exerciseDetail?.lessonName}</span
              ><span class="exercise-name"> > </span><span
                >{exerciseDetail?.title}
                ({exerciseDetail?.type})</span
              >
            {/if}
          </div>
        </div>
        <div class="col-auto exercise-detail-box">
          <div class="exercise-detail float-right">
            {#if loginDetail.lessonId}
              <p>Lesson: {loginDetail?.lessonId}</p>
            {/if}
            <p>Exercise: {exercisePin}</p>
            <div>{loginDetail?.userName}</div>
          </div>
        </div>
      </div>

      {#if !exerciseDetail}
        <div style="display: flex; justify-content:center;">Loading ...</div>
      {:else}
        <Instuctions {exerciseDetail} />
        <div class="row">
          <div class="col">
            <div
              class="jumbotron"
              on:mouseup={getSelectionText}
              on:keyup={getSelectionText}
              on:selectionchange={getSelectionText}
            >
              {#if exerciseDetail.type === 'READ'}
                {#if exerciseDetail.courseLanguage === 'zh-TW'}
                  <pre class="textToRead pinyin">{exerciseDetail.text}</pre>
                {:else}
                  <pre class="textToRead">{exerciseDetail.text}</pre>
                {/if}
              {:else if exerciseDetail.type === 'QUESTION'}
                {#if exerciseDetail.courseLanguage === 'zh-TW'}
                  <pre class="textToRead pinyin">{exerciseDetail.question}</pre>
                {:else}
                  <pre class="textToRead">{exerciseDetail.question}</pre>
                {/if}
              {:else if exerciseDetail.type === 'QUESTION_FIX_ANSWER'}
                <pre class="textToRead">{exerciseDetail.question}</pre>
              {:else if exerciseDetail.type === 'VIDEO'}
                <div align="center">
                  <iframe
                    title="youtube video"
                    width="100%"
                    height="350"
                    src={exerciseDetail.youtubeUrl}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
                <pre class="textToRead">{exerciseDetail.question}</pre>
              {:else if exerciseDetail.type === 'SELF_PRACTICE'}
                {#if exerciseDetail.courseLanguage === 'zh-TW'}
                  <textarea
                    class="self-practice-textarea pinyin"
                    bind:value={exerciseDetail.selfPracticeText}
                    placeholder="Input text here"
                  />
                {:else}
                  <textarea
                    on:change={onChangeCleanUnicodeText}
                    class="self-practice-textarea"
                    bind:value={exerciseDetail.selfPracticeText}
                    placeholder="Input text here"
                  />
                {/if}
              {:else if exerciseDetail.type === 'IMAGE'}
                <div align="center">
                  <div>
                    <img
                      class="customImage"
                      src={exerciseDetail.imageUrl}
                      alt="Question"
                    />
                  </div>
                </div>
                <pre class="textToRead">{exerciseDetail.question}</pre>
              {:else if exerciseDetail.type === 'READ_IMAGE'}
                <div align="center">
                  <div>
                    <img
                      class="customImage"
                      src={exerciseDetail.imageUrl}
                      alt="Question"
                    />
                  </div>
                </div>
                <pre class="textToRead">{exerciseDetail.question}</pre>
              {:else if exerciseDetail.type === 'MULTI_CHOICE'}
                <table class="table">
                  <thead><th>Option</th><th>Text</th></thead>
                  <tbody>
                    {#each exerciseDetail?.multiChoiceAnswers as mc}
                      <tr>
                        <th scope="row" />
                        <td>{mc}</td>
                      </tr>
                    {/each}
                  </tbody>
                </table>
              {:else}Wrong Type
              {/if}
              {#if textToRead}
                <!-- <TextToSpeech textToRead={textToRead} language={exerciseDetail.language}></TextToSpeech> -->
                <div class="float-right">
                  <TextToSpeech2
                    {textToRead}
                    language={exerciseDetail.courseLanguage}
                  />
                  <TextToSpeech2
                    {textToRead}
                    language={exerciseDetail.courseLanguage}
                    slowFlag
                  />
                </div>
              {/if}
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col offset-md-0 text-center"
            style="padding-top: 8px;padding-bottom: 8px;"
          >
            {#if !submitSuccess}
              <SpeechToTextOpus
                on:message={handleNewRecording}
                bind:recordingButtonState
              />
              <!-- {:else}
              <i class="fas fa-microphone-alt-slash" /> -->
            {/if}
            {#if recordingButtonState === 'stop'}
              <UploadResult
                on:message={handleResultSubmit}
                on:openLesson={handleOpenLesson}
                on:openExercise={nextExercise}
                on:tryAgainExercise={tryAgain}
                {loginDetail}
                {exerciseDetail}
                lastRecording={recordings.slice(-1)[0]}
              />
            {/if}
          </div>
        </div>

        {#key retryCounter}
          <ResultDisplay2
            on:openExercise={nextExercise}
            {resultData}
            lastRecording={recordings.slice(-1)[0]}
            language={exerciseDetail.courseLanguage}
            courseScoringType={exerciseDetail.courseScoringType}
            exerciseType={exerciseDetail.type}
            {exerciseDetail}
          />
        {/key}
      {/if}
    </div>
  </div>
{/if}

<style>
  .btn-navigation {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    color: #ffffff;
  }

  button:disabled {
    background-color: gray !important;
  }

  .textToRead {
    color: black;
    font-size: 1rem;
    text-align: justify;
    white-space: pre-wrap;
    padding: 4px;
  }

  @media (min-width: 768px) {
    .textToRead {
      font-size: 1.5rem;
    }
    .customImage {
      max-width: 600px;
      max-height: 600px;
    }
  }

  @media (max-width: 768px) {
    .exercise-detail-box {
      display: none;
    }
    .customImage {
      max-width: 100%;
    }
    .container {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  /* audio {
    background-color: aliceblue;
  } */

  .exercise-title {
    font-weight: 600;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    .exercise-name {
      display: none;
    }

    .jumbotron {
      padding: 1rem 1rem 4rem 1rem !important;
      margin-bottom: 0.5em;
    }
  }

  .exercise-detail {
    border: 1px;
    border-color: #9d9fa2;
    border-style: solid;
    border-radius: 5px;
    margin: 0.5em;
    padding: 0.5em;
  }

  .jumbotron {
    padding: 0.5em 2rem 4rem 2rem;
  }

  @font-face {
    font-family: 'Hanzi-Pinyin';
    src: url('../assets/fonts/Hanzi-Pinyin-Font.top.woff2') format('woff');
  }

  .pinyin {
    font-family: 'Hanzi-Pinyin', Arial;
    font-size: 250%;
  }

  .self-practice-textarea {
    width: 100%;
    height: 8em;
  }
</style>
